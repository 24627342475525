<template>
  <CRow class="settings-detail">
    <CCol col="12">
      <CCard class="zq--wizard-card">
        <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
          <div class="d-flex justify-content-between">
            <strong class="title">Settings</strong>
            <CLink class="card-header-action btn-minimize">
              <ClCardArrow :is-collapsed="basicIsCollapsed" />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse :show="basicIsCollapsed" :duration="400" v-if="Object.keys(modelFields).length">
          <CCardBody>
            <ZqFieldsView
              v-if="Object.keys(texts).length"
              :texts="texts.createPage.summary.settings"
              :formData="settingsData"
              :modelFields="modelFields"
            />
          </CCardBody>
        </CCollapse>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import ClCardArrow from '@/shared/UI/ClCardArrow';
import ZqFieldsView from '@/shared/components/ZqFieldsView';

export default {
  name: 'Settings',
  components: {
    ClCardArrow,
    ZqFieldsView,
  },
  props: {
    settingsData: Object,
    isCollapsed: {
      type: Boolean,
      default: true
    },
    modelFields: {
      type: Object,
      default() {
        return {};
      }
    },
  },
  inject: ['model'],
  data() {
    return {
      basicIsCollapsed: true,
      data: {},
      texts: {},
    }
  },
  async created() {
    this.data = this.settingsData;
    this.basicIsCollapsed = this.isCollapsed;
    let jsonTextsModel = await import(`@/config/pageTexts/${this.model}.json`)
    this.texts = {...jsonTextsModel[`${this.model}Texts`]};
  },
  methods: {
    getContent(val) {
      if (typeof val === 'object' && val !== null) {
        let stringVal = '';
        if (Object.keys(val).length) {
          let itemsStringArray = []
          for (let key in val) {
            if (key) {
              itemsStringArray.push(key + ': ' + val[key])
            }
          }
          if (itemsStringArray.length) {
            stringVal = itemsStringArray.join('<br/>');
          }
        }
        return stringVal;
      } else {
        return val
      }
    }
  },
  watch: {
    settingsData: {
      deep: true,
      handler(val) {
        this.data = val;
      }
    }
  }
}
</script>

<style lang="scss">
.settings-detail {
  .group {
    background: #2eb85c;
    color: #ffffff;
    border-radius: 4px;
  }
  @media screen and (max-width: 576px) {
    .overview-content {
      text-align: center;
    }
  }
}
</style>